import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../components/layout/DashboardHeader";
//import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../css/global.css';
import PackageData from "./data/PackageData";
import EditAddress from "./components/EditAddress";
import Global from "../Global";
import { params_care_health_load_quote } from "./data/params/care_health";
import http from "../http";
import Swal from "sweetalert2";
import ViewHealthQuotationlist from "./components/ViewHealthQuotationlist";
import { calculateDOB, decodeSumInsured, get_gender_short } from "../components/global";
import PlusMinusInputHealth from "./components/PlusMinusInputHealth";

export default function HealthPlanQuote() {

  const [activeTab, setActiveTab] = useState("quote");
  // eslint-disable-next-line
  const [rightTab, setRightTab] = useState("quote_r");

  const checkTab = (tab) => {
    setActiveTab(tab);

    if (tab === "family") {
      setRightTab("family_r");
    } else {
      setRightTab("quote_r");
    }
  };

  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  // eslint-disable-next-line
  const [isActive, setActive] = useState(false);
  // eslint-disable-next-line
  const [action, setAction] = useState();
  // eslint-disable-next-line
  const handleClose = () => setShow(false);

  const getCurrentDate = (offsetDays = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + offsetDays);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  // eslint-disable-next-line
  const toggleClass = (status, action) => {
    setActive(status);
    setAction(action);
  };

  const [form_data, setFormData] = useState(localStorage.getItem('form_data') ? JSON.parse(localStorage.getItem('form_data'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {});



  const [memberCount, setMemberCount] = useState(0);
  // eslint-disable-next-line
  const [packages, setPackages] = useState(PackageData);

  const [selected_cover, setSelectCover] = useState(1000000);
  // eslint-disable-next-line
  const [filter_packages, setFilterPackages] = useState(packages);


  const [cover_required_values, set_cover_required_values] = useState(1000000);
  useEffect(() => {
    setSelectCover(cover_required_values);
  }, [cover_required_values])

  useEffect(() => {
    setFormData({ ...form_data, desired_cover_amount: selected_cover });
    // eslint-disable-next-line
  }, [selected_cover])

  useEffect(() => {
    if (selected_cover === 0) {
      setFilterPackages(packages);
    } else {
      let filteredData = packages.filter((obj) => obj.cover === selected_cover);
      setFilterPackages(filteredData);
    }

    let c = 0;
    c += (form_data.self === 'true' || form_data.self ? 1 : 0);
    c += (form_data.spouse === 'true' || form_data.spouse ? 1 : 0);
    c += (form_data.son_arr ? (form_data.son_arr).length : 0);
    c += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);
    setMemberCount(c);
    localStorage.setItem('form_data', JSON.stringify(form_data));
    // eslint-disable-next-line
  }, [selected_cover, form_data])



  // drow kids age list 
  // eslint-disable-next-line
  const [kids_age_list, setKidsAgeList] = useState(() => {
    const arr = [];
    if (form_data.son_arr || form_data.daughter_arr) {

      if (form_data.son_arr) {
        // eslint-disable-next-line
        form_data.son_arr.map((v, i) => { arr.push(v.age + 'Y') });
      }

      if (form_data.daughter_arr) {
        // eslint-disable-next-line
        form_data.daughter_arr.map((v, i) => { arr.push(v.age + 'Y') });
      }
    }
    return arr;
  })

  const [editEddressFlag, setEditAddressFlag] = useState(false);
  //=================
  const [plan_available, setPlanAvailable] = useState(0);
  // eslint-disable-next-line
  const [age_group, set_age_group] = useState([]);

  const [api_response, set_api_response] = useState([])
  const [no_response, set_no_response] = useState(false);

  const fetchData = () => {
    set_no_response(true);
    set_api_response(false);

    /**
     * default load
     */
    let arr = [];
    if (form_data.self) {
      arr.push(form_data.self_age);
    }
    if (form_data.spouse) {
      arr.push(form_data.spouse_age);
    }
    if (form_data.son_arr) {
      // eslint-disable-next-line
      form_data.son_arr.map((v, i) => {
        arr.push(v.age);
      });
    }
    if (form_data.daughter_arr) {
      // eslint-disable-next-line
      form_data.daughter_arr.map((v, i) => {
        arr.push(v.age);
      });
    }

    let age_keys = ['Age1', 'Age2', 'Age3', 'Age4', 'Age5', 'Age6'];
    let age_group_ = {};
    // Iterating through age_keys and age_values to create the desired object
    for (let i = 0; i < age_keys.length; i++) {
      age_group_[age_keys[i]] = arr[i];
    }
    set_age_group(age_group_);
    /**
     * end default load 
     */


    const post_data = params_care_health_load_quote;
    post_data.InputData.PolicyRiskList[0].SIInLacs = selected_cover / 100000;
    post_data.InputData.PolicyRiskList[0].TotalCount = arr.length; // memberCount;  

    let ChildCount = (form_data.son_arr ? (form_data.son_arr).length : 0);
    ChildCount += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);

    let maxAge = form_data.self_age;
    for (let key in age_group_) {
      if (age_group_[key] !== "" && !isNaN(age_group_[key])) {
        let age = parseInt(age_group_[key]);
        if (age > maxAge) {
          maxAge = age;
        }
      }
    }

    post_data.InputData.PolicyRiskList[0].MaxInsuredAge = maxAge;
    post_data.InputData.PolicyRiskList[0].ChildCount = ChildCount;
    post_data.InputData.PolicyRiskList[0].AgeGroup = [age_group_];
    post_data.InputData.PolicyCustomerList = [{
      "City": form_data.city ? form_data.city : "",
      "Pincode": form_data.pin ? form_data.pin : "400093"
    }]

    console.log('form_data.self_age ', calculateDOB(form_data.self_age), 'self age', form_data.self_age);

    const PolicyRiskList = [];

    const formData = form_data;

    // If self is insured, add to list
    if (formData.self) {
      PolicyRiskList.push({
        ProductElementCode: "R00001",
        DateOfBirth: calculateDOB(formData.self_age), // Assuming birth on Jan 1 for simplicity
        GenderCode: formData.gender.charAt(0).toUpperCase(),
        InsuredRelation: "1"  // Increment relationId for each entry
      });
    }

    // If spouse is insured, add to list
    if (formData.spouse) {
      PolicyRiskList.push({
        ProductElementCode: "R00001",
        DateOfBirth: calculateDOB(formData.spouse_age), // Assuming birth on Jan 1 for simplicity
        GenderCode: formData.gender === "male" ? "F" : "M", // Assuming opposite gender of self
        InsuredRelation: formData.gender === "male" ? "3" : "2",  // Increment relationId for each entry
      });
    }
    console.log('formData.son_arr', formData.son_arr);
    // Adding sons if son_arr is present and is an array
    if (formData.son_arr && Array.isArray(formData.son_arr)) {
      formData.son_arr.forEach((son, index) => {

        PolicyRiskList.push({
          ProductElementCode: "R00001",
          DateOfBirth: calculateDOB(son.age), // Assuming birth on Jan 1 for simplicity
          GenderCode: "M",
          InsuredRelation: "4"  // Increment relationId for each entry
        });

      });
    }

    if (formData.daughter_arr && Array.isArray(formData.daughter_arr)) {
      formData.daughter_arr.forEach((daughter, index) => {
        PolicyRiskList.push({
          ProductElementCode: "R00001",
          DateOfBirth: calculateDOB(daughter.age), // Assuming birth on Jan 1 for simplicity
          GenderCode: "F",
          InsuredRelation: "5"  // Increment relationId for each entry
        });
      });
    }

   

    const floater = PolicyRiskList.some(item => parseInt(item.InsuredRelation) > 1);
   // console.log("floater : ",floater,"PolicyRiskList _248_", PolicyRiskList);

    const selfCnt = form_data.self ? 1 : 0;
    const spouseCnt = form_data.spouse ? 1 : 0;
    const daughtCnt = (form_data.daughter_arr ? (form_data.daughter_arr).length : 0)
    const sonCnt = (form_data.son_arr ? (form_data.son_arr).length : 0)

    // Determine adult count
    const adultCount = selfCnt + spouseCnt;
    // Determine child count
    const childCount = sonCnt + daughtCnt;
    // Define logic table
    const logicTable = [
      { FFCategoryId: 1, AdultCount: 1, ChildCount: 0 },
      { FFCategoryId: 2, AdultCount: 1, ChildCount: 1 },
      { FFCategoryId: 3, AdultCount: 1, ChildCount: 2 },
      { FFCategoryId: 4, AdultCount: 2, ChildCount: 0 },
      { FFCategoryId: 5, AdultCount: 2, ChildCount: 1 },
      { FFCategoryId: 6, AdultCount: 2, ChildCount: 2 }
    ];

    // Find matching FFCategoryId
    let matchingFFCategoryId = null;
    for (let i = 0; i < logicTable.length; i++) {
      const condition = logicTable[i];
      if (condition.AdultCount === adultCount && condition.ChildCount === childCount) {
        matchingFFCategoryId = condition.FFCategoryId;
        break;
      }
    }

    const post_data_new = {
      ProductVersion: "1.0",
      ProductTag: "1",
      HealthPolicyType: floater ? "2" : "1",
      LOBCode: "2",
      FFCategory: matchingFFCategoryId,
      SumInsured: decodeSumInsured(selected_cover), // "2", //form_data.desired_cover_amount.toString(), // "1000000",
      IsSelfCovered: selfCnt > 0 ? "Yes" : "No",
      EffectiveDate: getCurrentDate(),
      ExpiryDate: getCurrentDate(364),
      PolicyCustomerList: [
        {
          DateOfBirth: calculateDOB(form_data.self_age), // "1990-11-26",
          PostCode: form_data.pin ? (form_data.pin).toString() : "400093", //"411033",
          City: form_data.city ? form_data.city : "", // "PUNE",
          GenderCode: get_gender_short(form_data.gender) //"M"
        }
      ],
      PolicyLobList: [
        {
          ProductCode: "FINHEALTH",
          PolicyRiskList: PolicyRiskList
        }
      ]
    }
    const post = {
      form_data: form_data,
      api_post_data: post_data_new
    }

    try{

      http.post('/health/loadQuote', post).then((response) => {
        const resp = response.data; 
        
        set_api_response(resp);
  
        if (resp && resp[0] && resp[0].CarrierCode) {
  
          let count_ = 0;
          // eslint-disable-next-line
          resp.map((v, i) => {
            count_ += v.ProductName ? 1 : 0;
          });
          setPlanAvailable(count_);
  
  
        } else if (resp.status && resp.status === 401) {
          localStorage.removeItem('access_token');
  
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: resp.message,
          }).then(() => {
            window.location.href = Global.base_url;
          })
        } else if (resp.CarrierCode && resp.ClientRequestId) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: resp.Message,
          });
        } else if (resp.ResponseCode && resp.ResponseCode === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: resp.Message,
          });
        } else if (resp.Message) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: resp.Message,
          });
        } else { 

          var errString = typeof resp === 'string' ? resp : ''; 
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong with the request. - ' + errString ,
          }).then(() => {
            // window.location.reload();
            set_no_response(false);
          })
        }
  
  
      }).catch((error) => {
        console.error('HTTP request failed:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong with the request!',
        }).then(() => {
          window.location.reload();
        })
      });

    }catch (e) {
      console.error('An unexpected error occurred:', e);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An unexpected error occurred. Please try again.',
      }).then(() => {
        window.location.reload();
      })
    }


  }



  useEffect(() => {
    set_no_response(false);
    setTimeout(function () {
      fetchData();
    }, 300);
    // eslint-disable-next-line
  }, []);


  return (
    <Fragment>
      <DashboardHeader />

      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="form_title">
            <div className="mt30 font21 fw600 color384">
              Suggested Health Insurance Plans
            </div>
            <div className="font16 color626">
              Based on the inputs provided by you, here are some plans for you.
            </div>
            <div className="mt30">
              {/*  <Link to={"/"} className="btn bluebtn wizard-btn backtowizardBtn font14 fw600">Back</Link>         */}
            </div>
          </div>

          <div className="dashboard_outer mt30">
            {/*------left tab start */}
            <div className="dashboard_left pb20">
              <div className="db_maintab_outer mt25">
                <div className="db_maintab_wpr">
                  <nav className="font13">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{ 'justifyContent': 'left' }}>
                      <a
                        className={
                          activeTab === "family"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                        id="nav-family-tab"
                        data-toggle="tab"
                        href="#nav-family"
                        role="tab"
                        aria-controls="nav-family"
                        aria-selected="false"
                        onClick={() => checkTab("family")}
                      >
                        Inputs
                      </a>
                      <a className={activeTab === "quote" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-quote-tab" data-toggle="tab" href="#nav-quote"
                        role="tab" aria-controls="nav-quote" aria-selected="true" onClick={() => checkTab("quote")} > Quote
                      </a>
                    </div>
                  </nav>
                </div>
                <div className="tab-content" id="nav-tabContent">

                  <div className={activeTab === "family" ? "tab-pane fade show active" : "tab-pane fade"}
                    id="nav-family"
                    role="tabpanel"
                    aria-labelledby="nav-faHealthPlanQuotemily-tab">
                    {activeTab === "family" &&
                      <>
                        <div className="familycard p25">
                          <div className="familycard_title">
                            <div className="color182 font14 fw600 familycard_title_left">Family members</div> 
                            <div className="title_viewmore"><Link to={"/health-plan"} ><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link> </div>
                          </div>

                          {/*  <div className="ml25 color182 font14 fw600 familycard_title_left"> 
                              Went Cover For
                            </div> */}
                          <div className="categoryTabs_outer mt20" style={{ padding: '0px' }}>

                            {form_data.self === true && (
                              <li>
                                <div className="categoryTab font12 fw500 color353">
                                  <span className="risk mr5" /> Self
                                </div>
                              </li>

                            )}
                            {form_data.spouse === true && (
                              <li>
                                <div className="categoryTab font12 fw500 color353">
                                  <span className="risk mr5" /> Spouse
                                </div>
                              </li>
                            )}
                            {form_data.kids === true && (
                              <li>
                                <div className="categoryTab font12 fw500 color353">
                                  <span className="family_icon mr5" /> Kids
                                </div>
                              </li>
                            )}

                          </div>
                          <div className="familycard_content mt20">
                            <div className="familycard_content_left">
                              <div className="fw500 colora5b font12"><span className="familyicon mr5"></span> Members</div>
                              <div className="fw600 color162 font18">{memberCount}</div>
                            </div>
                            <div className="familycard_content_mdl">
                              <div className="fw500 colora5b font12">Adults</div>
                              <div className="fw600 color162 font14">
                                {form_data.self_age && form_data.self_age + 'Y' }
                                {form_data.spouse_age  && ', ' + form_data.spouse_age + 'Y' }
                                </div>
                            </div>
                            <div className="familycard_content_right">
                              <div className="fw500 colora5b font12">Kids</div>
                              <div className="fw600 color162 font14"> {kids_age_list.join(', ')}   </div>
                            </div>
                          </div>
                        </div>

                        <div className="familycard  p25">
                          <div className="familycard_title">
                            <div className="color182 font14 fw600 familycard_title_left"><span className="fundicon mr5" /> Pre Exiting Disease  </div>
                            {/* <div className="title_viewmore"><span className="font12 fw600 color0d6 pointer"> <div className="goaledit_icon ml4"></div> </span> </div> */}
                          </div>
                          <div className="familycard_content mt20">
                            <div className="categoryTabs_outer" style={{ padding: '0px' }}>
                              {
                                form_data.any_existing_illness &&
                                form_data.any_existing_illness.map((v) => {
                                  return (<li>
                                    <div clHealthPlanQuoteassName="categoryTab font12 fw500 color353"> {v === 'None of these' ? 'No illness' : v}</div>
                                  </li>)
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div className="familycard graybg p25">
                          <div className="familycard_title">
                            <div className="color182 font14 fw600 familycard_title_left"><span className="insuranceicon mr5" />Address </div>
                            <div className="title_viewmore"><span className="font12 fw600 color0d6 pointer" onClick={() => setEditAddressFlag(true)}> <div className="goaledit_icon ml4"></div> </span> </div>
                          </div>
                          <div className="familycard_content mt20">
                            {(form_data.city || form_data.pin) &&
                              <li style={{ 'margin-top': '-3px' }}>
                                <div className="categoryTab font12 fw500 color353">
                                  <span className="fa fa-map-marker mr5"></span>  { /* form_data.city ? form_data.city : form_data.pin */ form_data.city + ' (' + form_data.pin + ')'}
                                </div>
                              </li>
                            }
                          </div>
                        </div>
                      </>
                    }
                  </div>

                  <div className={activeTab === "quote" ? "tab-pane fade show active" : "tab-pane fade "}
                    id="nav-quote"
                    role="tabpanel"
                    aria-labelledby="nav-quote-tab"
                  >
                    {activeTab === "quote" &&
                      <>
                        <div className="tab-pane fade show active" id="nav-plan" role="tabpanel" aria-labelledby="nav-plan-tab">

                          <PlusMinusInputHealth amount={cover_required_values} setAmount={set_cover_required_values} className="p10 mt30" />

                          {/*<div className="targetbar_outer mt25">
                            <div className="targetbar_title">
                              <div className="font14 fw500 color182">Desired Cover Amount</div>
                              <div>
                                <div className="tragetvalue font13 fw700 colorf47"><span className="orangeDot mr5"></span>{selected_cover / 100000} L</div>
                              </div>
                            </div>

                            
                            <div className="targetbar mt10">



                              <form className="target_range_bar">
                                <div className="form-group">
                                  <Slider
                                    marks={{
                                      700000: (<><div className="valuecount valuecount_green font14 fw400">7L</div></>),
                                      1000000: (<div className="valuecount valuecount_primary font14 fw400">10L </div>),
                                      2000000: (<div className="valuecount valuecount_blue font14 fw400">20L</div>),
                                      3000000: (<div className="valuecount valuecount_gold font14 fw400">30L</div>),
                                      4000000: (<div className="valuecount valuecount_gold font14 fw400">40L</div>),
                                      5000000: (<div className="valuecount valuecount_red font14 fw400">50L</div>),
                                    }}
                                    handle={null}
                                    step={null}
                                    value={selected_cover}
                                    name={"desire"} className='mt15' min={700000} max={5000000} onChange={e => { setSelectCover(e) }} />
                                </div>
                              </form>
                            </div>
                          </div>*/}

                        </div>

                        <div className="targetbar_outer_ pl25 pr25 pt30">
                          <input type="button" className="btn btn-primary continue-to-buy-btn " value="Get Quotation" onClick={fetchData} />
                        </div>

                      </>
                    }
                  </div>



                </div>
              </div>
            </div>
            {/*------left tab end */}

            {/*------right tab start */}
            <div className="dashboard_right">
              <div className="graphvalue">
                <div className="graphvalue_left">
                  <div className="graphvalue_count font22 fw500">
                    <span className='font16'>{plan_available}  Plan available</span>
                  </div>
                </div>
                <div className="graphvalue_right">
                  <div className="graphvalue_count_ font22 fw500">
                    {/*<Link to={'/compare-plans'}><span className='font16'> <i className="fa-solid fa-list-ul"></i> Compare</span></Link>*/}
                  </div>
                </div>
              </div>
              <div className="graphtabs_outer mt25 pb30" style={{ 'min-height': '500px' }}>
                <div className="graphtabs">
                  <nav className="font12">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a className="nav-item nav-link active" id="nav-activeyears-tab" data-toggle="tab" href="#nav-activeyears" role="tab" aria-controls="nav-activeyears" aria-selected="true">Available plans</a>
                    </div>
                  </nav>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-activeyears" role="tabpanel" aria-labelledby="nav-activeyears-tab">
                    {
                      typeof api_response === 'object' ? <ViewHealthQuotationlist data={api_response} form_data={form_data} view_details_link="health-insurance-details/health-plan" />
                        : <> <Loaders loader={no_response} /></>
                    }
                    {/*<ProductList  product_arr={filter_packages} planType="health-plan" view_details_link="health-insurance-details/health-plan-old" /> */}

                  </div>

                </div>
              </div>
            </div>
            {/*------right tab end */}

          </div>
        </div>
      </div>
      {
        editEddressFlag && <EditAddress setEditAddressFlag={setEditAddressFlag} session_name={'form_data'} />
      }
    </Fragment>
  );
}


const Loaders = (props) => {
  return props.loader ? <img style={{ width: '64px' }} src={Global.base_url + "/images/icons/loader.gif"} alt="" /> : 'No Records !';
}