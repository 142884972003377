import { db_date } from "../components/global";
import http from "../http";
export const getQuoteDefaultIcici = (CarrierCode, params_icici_life_getQuoteFinalised, params_hdfc_life_getQuoteFinalised, riderSumInsured, determineLifeCover, frequency, selectedDBOption, DBPayoutTerm, setDefaultRiderI, setGetDefaultRiderQuote, getRiders) => {
  // Add your logic here, e.g., API call or calculation
  console.log("getQuoteNew function called", getRiders);

  let post_data = {};

  if (CarrierCode === 'ICICI') {
    post_data = params_icici_life_getQuoteFinalised;
  }else{
    post_data = params_hdfc_life_getQuoteFinalised;
  }



  const form_data = localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {};


  const c = form_data.cover_till - form_data.age;

  const userTerm = parseInt(c ? c : (85 - parseInt(form_data.age)));

  post_data.PPT = form_data.PPT ? form_data.PPT : (!isNaN(parseInt(c)) ? c : (85 - form_data.age));


  let riderSumInsured_ = riderSumInsured;
  if (form_data.PPT === 1) {
    riderSumInsured_ = parseInt(form_data.SumInsured) / 20;
  }

  let RiderDetails = [];

  if (CarrierCode === 'ICICI') {

    RiderDetails = [
      // below for  ICICI
      { // 7
        "CarrierRiderDesc": "Accelerated Critical Illness (ACI) Benefit",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "CIBF",
        "Term": userTerm,
        "SumInsured": getRiders.CIBF ? getRiders.CIBF : (form_data.SumInsured > 1000000 ? 2500000 : 1000000),
        "PPT": post_data.PPT,
        "PPTOption": "REGULAR",
        "cat": "ICICI"
      },
      { // 8
        "CarrierRiderDesc": "Accident Death Cover",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "ADBU",
        "Term": userTerm,
        "SumInsured": getRiders.ADBU ? getRiders.ADBU : riderSumInsured_, // Default to riderSumInsured_ if not passed
        "PPT": post_data.PPT,
        "PPTOption": "REGULAR",
        "cat": "ICICI"
      }
    ];

  }else{

    RiderDetails = [
      { //1
        "CarrierRiderDesc": "Accidental Disability Rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "INAD",
        "Term": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? Math.min(40, (75 - form_data.age)) : userTerm,
        "SumInsured": riderSumInsured_ > 20000000 ? 20000000 : riderSumInsured_,
        "PPT": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? Math.min(40, (75 - form_data.age)) : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },
  
      /*{
        "CarrierRiderDesc": "Personal Accident Cover rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_PAC", // 
        "Term": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? (75 - form_data.age) : userTerm,
        "SumInsured": riderSumInsured_ > 5000000 ? 500000 : riderSumInsured_,
        "PPT": (parseInt(userTerm) + parseInt(form_data.age)) > 75 ? Math.min(post_data.PPT, (75 - form_data.age)) : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },*/  

      { // 3
        "CarrierRiderDesc": "Accidental death cover",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_ADC", // 
        "Term": (userTerm + form_data.age) > 75 ? (75 - form_data.age) : userTerm,
        "SumInsured": riderSumInsured_ > 5000000 ? 500000 : riderSumInsured_,
        "PPT": (userTerm + form_data.age) > 75 ? Math.min(post_data.PPT, (75 - form_data.age)) : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },
      { // 5
        "CarrierRiderDesc": "Comprehensive CI",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "HPR_CCI",
        "Term": Math.min(15, userTerm),
        "SumInsured": riderSumInsured_ > 5000000 ? 5000000 : riderSumInsured_,
        "PPT": Math.min(40, post_data.PPT),
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },
  
  
      { // 6
        "CarrierRiderDesc": "Critical Illness Plus Rider",
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "C4CI",
        "Term": userTerm > 40 ? 40 : userTerm,
        "SumInsured": riderSumInsured_ > 15000000 ? 20000000 : 1000000,
        "PPT": userTerm > 40 ? 40 : userTerm,
        "PPTOption": "REGULAR",
        "cat": "HDFC"
      },
      
    ];

  }


  let pptOption = form_data.PPT === 1 ? "SINGLE" : form_data.PPT ? (userTerm !== form_data.PPT ? "LIMITED" : "REGULAR") : "REGULAR";

  post_data.PPTOption = pptOption
  post_data.Education = form_data.education;

  post_data.LifeCoverOption = determineLifeCover();
  post_data.CarrierPlanId = determineLifeCover();
  post_data.CarrierPlanName = determineLifeCover();
  post_data.ProductCode = determineLifeCover();

  post_data.SumInsured = form_data.SumInsured;
  post_data.Term = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));
  post_data.TOBstatus = form_data.smoked_status ? form_data.smoked_status : 'N';

  post_data.PolicyLobList[0].PolicyRiskList[0].DateOfBirth = db_date(form_data.dob);
  post_data.PolicyLobList[0].PolicyRiskList[0].Gender = form_data.gender;

  post_data.PolicyLobList[0].PolicyRiskList[0].Email = (form_data.email ? form_data.email : '');
  post_data.PolicyLobList[0].PolicyRiskList[0].Mobile = (form_data.mobile ? form_data.mobile : '');


  post_data.PolicyLobList[0].PolicyRiskList[0].OccupationCode = (form_data.occupation).toString();

  post_data.PolicyLobList[0].PolicyRiskList[0].City = form_data.city;
  post_data.PolicyLobList[0].PolicyRiskList[0].PostCode = form_data.pin;

  if (form_data.full_name) {
    const [first, ...restname] = form_data.full_name.split(' ');

    post_data.PolicyLobList[0].PolicyRiskList[0].FirstName = first;
    post_data.PolicyLobList[0].PolicyRiskList[0].LastName = restname.join(' ');
  }



  post_data.AnnualIncome = form_data.annual_income;
  post_data.Frequency = form_data.PPT === 1 ? "5" : frequency;

  post_data.DBPayoutTerm = "0";
  post_data.LSPercentage = selectedDBOption === "4" ? "50" : "0";


  post_data.DBPayoutTerm = DBPayoutTerm;

  // HERE IS FILTER ADDONS ACCORING TO CHECKED ADDONS LIST 
  if (form_data.PPT === 1) {
    post_data.PolicyLobList[0].RiderDetails = [];
  } else {
    post_data.PolicyLobList[0].RiderDetails = RiderDetails;
  }

  post_data.PPTOption = (form_data.cover_till === 99 ? 'REGULAR WHOLE LIFE' : post_data.PPTOption);

  // overwrite RiderDetails PPT value form base PPT In Case PPTOption = LIMITED and CarrierCode = HDFC
  if (post_data.PPTOption === 'LIMITED' && post_data.CarrierCode === 'HDFC') {
    // eslint-disable-next-line
    post_data.PolicyLobList[0].RiderDetails.map((v, i) => {
      //console.log(" base ppt " + post_data.PPT );
      post_data.PolicyLobList[0].RiderDetails[i].PPT = post_data.PPT;
    });
  }



  post_data.DBOption = selectedDBOption;
  /* post_data.DBPayoutTerm = 10; */

  let post = {
    form_data: form_data,
    api_post_data: post_data
  }


  console.log('post', post);
  http.post('/getQuote', post).then((response) => {

    const resp = response.data;

    const single_data = resp.filter((obj) => obj.CarrierCode === CarrierCode)[0];

    console.log('single_data', single_data);

    if (CarrierCode === 'ICICI') {
      setDefaultRiderI(single_data.PolicyLobList[0].RiderDetails);
      setGetDefaultRiderQuote(single_data.Riders);
    }else{
      setDefaultRiderI(single_data.PolicyLobList[0].RiderDetails);
      setGetDefaultRiderQuote(single_data.Riders);
    }


    
  
    return single_data;

  });

};