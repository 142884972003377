/**
 * 
 */
import React from 'react';
import { css } from '@emotion/react';

import { ScaleLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
 
const Loader = () => {
  return (
    <div className="sweet-loading" style={{'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'height': '100vh'}}>
      <ScaleLoader css={override} size={150} color={'#123abc'} loading={true} />
    </div>
  );
};

export default Loader;
