import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { inWords, makeInt } from "../../components/global";
import Swal from "sweetalert2";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import formatAmount from "indian-currency-formatter";
import { Card } from "bootstrap-4-react/lib/components";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import { useParams } from "react-router-dom";

const EditAddOns = ({
  setEditAddOnsFlag,
  setFormData,
  form_data,
  getQuoteNewData,
  setRiders,
  getRiders,
  riderDetails,
  accidentAddonArr,
  setAccidentAddon,
  checkedIciciAddon,
  setCheckedIciciAddon,
  uncheckedIciciAddon,
  setUncheckedIciciAddon,
  checkboxes,
  setCheckboxes
}) => {

 

  console.log("component passed checkedIciciAddon", checkedIciciAddon);
  const { handleSubmit } = useForm();
  const [old, setOld] = useState(form_data);

  // eslint-disable-next-line
  const [critical_illness, set_critical_illnessl] = useState(
    old.critical_illness ? old.critical_illness : 0
  );
  // eslint-disable-next-line
  const [accidental_death_benefit, set_accidental_death_benefit] = useState(
    old.accidental_death_benefit ? old.accidental_death_benefit : 0
  );
  // eslint-disable-next-line
  const [waiver_of_premium_on_ci, set_waiver_of_premium_on_ci] = useState(
    old.waiver_of_premium_on_ci ? old.waiver_of_premium_on_ci : 0
  );

/*   Swal.fire({
      position: "top-end",
      icon: "success",
      title: "AddOns Details Updated successfully",
      showConfirmButton: true,
      timer: 1500 
    }); */

  const onSubmitData = () => {
    setOld({
      ...old,
      critical_illness: critical_illness,
      accidental_death_benefit: accidental_death_benefit,
      waiver_of_premium_on_ci: waiver_of_premium_on_ci,
    });
    setFormData({
      ...old,
      critical_illness: critical_illness,
      accidental_death_benefit: accidental_death_benefit,
      waiver_of_premium_on_ci: waiver_of_premium_on_ci,
    });

  

    Swal.fire({
      icon: "success",
      title: "Success!",
      confirmButtonColor: "#009356",
      text: "AddOns Details Updated successfully.",
    }).then(() => {
      setEditAddOnsFlag(false);
    });
  };

  /*const [rider_val, set_rider_val] = useState(getRiders ? getRiders : {});

  const SliderOnChnage = (e, code) => {
    console.log('change of slider', e, 'code', code);
    // Use functional update form of useState
    set_rider_val(prevState => ({ ...prevState, [code]: e }));
    setRiders(prevState => ({ ...prevState, [code]: e }));
  }

  useEffect(() => {
    console.log('rider_val update', rider_val);
  }, [rider_val]); */

  const initialRiders = getRiders && Array.isArray(getRiders) ? getRiders : [];
  const [rider_val, set_rider_val] = useState(initialRiders);

  const SliderOnChnage = (e, code) => {
    // Use functional update form of useState
    set_rider_val((prevState) => ({ ...prevState, [code]: e }));
    setRiders((prevState) => ({ ...prevState, [code]: e }));
  };

  useEffect(() => {
    console.log("rider_val update", rider_val);
  }, [rider_val]); // Trigger the effect when rider_val changes

  // eslint-disable-next-line
  //const [riderSumInsured, setRiderSumInsured] = useState(form_data.SumInsured ? (form_data.SumInsured / (old.PPT === "1" ? 20 :  2) ) : 1000000);

  /*  const RadioChange = (e) => {
      setAccidentAddon(e.target.value);
    }*/

  /**
   *  for HDFC responsed riders list  - INAD | HPR_CC | PPR_ADC | PPR_PAC | HPR_CCI
   *
   *   "PPR_ADC Rider OR PPR_PAC Rider to be allowed both are not allowed."
   *   "HPR_CCI Rider OR HPR_CC Rider to be allowed both are not allowed."
   *
   */ 
console.log("::::line 114  checkedIciciAddon : ", checkedIciciAddon, " uncheckedIciciAddon : ", uncheckedIciciAddon);
  // eslint-disable-next-line
  const checkAddonChange = (e) => { 

    const code = e.target.value; 

  /*   console.log("::::line 120 code : ",code," checkedIciciAddon : ", checkedIciciAddon, " uncheckedIciciAddon : ", uncheckedIciciAddon);

    if(code === 'PPR_ADC_'){ 
      if (e.target.checked){
        setCheckedIciciAddon( [...checkedIciciAddon,  code] );  
        setCheckedIciciAddon( checkedIciciAddon.filter(function(item) { return item !== 'PPR_PAC'  }) ); 

        setUncheckedIciciAddon( uncheckedIciciAddon.filter((item) => item !== e.target.value) ); 

      }else{
        setCheckedIciciAddon( checkedIciciAddon.filter(function(item) { return item !== code  }) );  

        setUncheckedIciciAddon( [...uncheckedIciciAddon, e.target.value] );

      } 
    }else if(code === 'PPR_PAC_'){ 
      if (e.target.checked){
        setCheckedIciciAddon( [...checkedIciciAddon,  code] );  
        setCheckedIciciAddon( checkedIciciAddon.filter(function(item) { return item !== 'PPR_ADC'  }) ); 

        setUncheckedIciciAddon( uncheckedIciciAddon.filter((item) => item !== e.target.value) );  // --
      }else{
        setCheckedIciciAddon( checkedIciciAddon.filter(function(item) { return item !== code  }) );  
        setUncheckedIciciAddon( [...uncheckedIciciAddon, e.target.value] );
      } 
    }else{ */
      // old code 
      console.log("::::line 148 code : ",code," checkedIciciAddon : ", checkedIciciAddon, " uncheckedIciciAddon : ", uncheckedIciciAddon);
      if (checkedIciciAddon.includes(e.target.value)) { // unchecked 
        setCheckedIciciAddon(  checkedIciciAddon.filter((item) => item !== e.target.value) );
        setUncheckedIciciAddon([...uncheckedIciciAddon, e.target.value]);
      } else { // checked 
          setUncheckedIciciAddon( uncheckedIciciAddon.filter((item) => item !== e.target.value) );
          setCheckedIciciAddon([...checkedIciciAddon, e.target.value]);
        
      }

    //}

    console.log("::::line 158 code : ",code," checkedIciciAddon : ", checkedIciciAddon, " uncheckedIciciAddon : ", uncheckedIciciAddon);
  };
 


//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
/* "PPR_ADC Rider OR PPR_PAC Rider to be allowed both are not allowed."
*   "HPR_CCI Rider OR HPR_CC Rider to be allowed both are not allowed."
 */
// Step 1: Define initial state
/* const initialCheckboxes = {
  INAD: false,
  PPR_PAC: false,
  HPR_CC: false,
  PPR_ADC: false,
  HPR_CCI: false,
};

const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
 */
// Step 2: Handle change event with condition
const handleCheckboxChange = (event) => {
  const { name, checked } = event.target;

  setCheckboxes((prevCheckboxes) => {
    // If checkbox1 or checkbox2 is being checked, uncheck the other
    if (name === 'PPR_ADC' && checked) {
      return { ...prevCheckboxes, PPR_ADC: true, PPR_PAC: false };
    } else if (name === 'PPR_PAC' && checked) {
      return { ...prevCheckboxes, PPR_ADC: false, PPR_PAC: true };


    } else if (name === 'HPR_CCI' && checked) {
      return { ...prevCheckboxes, HPR_CCI: true, HPR_CC: false };
    } else if (name === 'HPR_CC' && checked) {
      return { ...prevCheckboxes, HPR_CCI: false, HPR_CC: true };
    } 
    
    else {
      return { ...prevCheckboxes, [name]: checked };
    }
  });
};


/*
  // for testing 
const [checked, setChecked] = useState([]);

   const checkAddonChange1 = (e) => {
   
    const code = e.target.value;
    console.log(" 164 checked_", checked)

    if(code === 'PPR_ADC'){ 
      if (e.target.checked){
        setChecked( [...checked,  code] );  
        setChecked( checked.filter(function(item) { return item !== 'PPR_PAC'  }) );   
      }else{
        setChecked( checked.filter(function(item) { return item !== code  }) );   
      } 
    }else if(code === 'PPR_PAC'){ 
      if (e.target.checked){
        setChecked( [...checked,  code] );  
        setChecked( checked.filter(function(item) { return item !== 'PPR_ADC'  }) );  
      }else{
        setChecked( checked.filter(function(item) { return item !== code  }) );  
        setChecked( [...checked, e.target.value] );
      } 
    }else{
      // old code 
      if (e.target.checked){
        setChecked(  checked.filter((item) => item !== e.target.value) ); 
      } else { 
        setChecked([...checked, e.target.value]);
        
        
      }

    }  
    console.log(" 192 checked_", checked)

  }; */

 // ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  /*const checkAddonChange = (e) => {
    const { value } = e.target;
    
    let newChecked = [...checkedIciciAddon];
    let newUnchecked = [...uncheckedIciciAddon];
    
    if (value === 'PPR_PAC') {
      newChecked = ['PPR_PAC'];
      newUnchecked = uncheckedIciciAddon.filter(item => item !== 'PPR_ADC');
    } else if (value === 'PPR_ADC') {
      newChecked = ['PPR_ADC'];
      newUnchecked = uncheckedIciciAddon.filter(item => item !== 'PPR_PAC');
    } else {
      if (checkedIciciAddon.includes(value)) {
        newChecked = newChecked.filter(item => item !== value);
      } else {
        newChecked.push(value);
      }
      newUnchecked = newUnchecked.filter(item => item !== value);
    }
    
    setCheckedIciciAddon(newChecked);
    setUncheckedIciciAddon(newUnchecked);
    
    console.log('checkedIciciAddon : ', newChecked);
  }*/

  console.log(" :::::::: getQuoteNewData.Riders : ", getQuoteNewData.Riders);
  const [style, setStyle] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        "overflow-y": "scroll",
        height: "100vh",
      });
    }, 500);
  }, []);

  const min_max_value = (Rider) => {
    let min1 = makeInt(Rider.MinSumInsured);

    console.log('form_data.SumInsured', form_data.SumInsured);
    let max1 = Rider.MaxSuminsured === "Same as Policy Sum Insured" ? form_data.SumInsured : makeInt(Rider.MaxSuminsured);

    // overwrite min value
    min1 = Rider.BenefitCode === "C0001786" ? Math.min(100000, min1) : min1; // Income Benefit on Accidental Disability
    min1 = Rider.BenefitCode === "C0001788" ? Math.min(100000, min1) : min1; // Cancer cover
    min1 = Rider.BenefitCode === "C0001725" ? Math.min(100000, min1) : min1; // Accidental death cover
    min1 = Rider.BenefitCode === "C0001789" ? Math.min(500000, min1) : min1; // Personal accidental cover
    min1 = Rider.BenefitCode === "C0001785" ? Math.min(500000, min1) : min1; // Comprehensive CI

    max1 = Rider.BenefitCode === "C0001786" ? Math.min(10000000, max1) : max1; // Income Benefit on Accidental Disability
    max1 = Rider.BenefitCode === "C0001788" ? Math.min(5000000, max1) : max1; // Cancer cover
    max1 = Rider.BenefitCode === "C0001725" ? Math.min(10000000, max1) : max1; // Accidental death cover
    max1 = Rider.BenefitCode === "C0001789" ? Math.min(5000000, max1) : max1; // Personal accidental cover
    max1 = Rider.BenefitCode === "C0001785" ? Math.min(5000000, (max1/2)) : (max1/2); // Comprehensive CI

    return [min1, max1];
  };

  const { CarrierCode } = useParams();

  let sortedArray = []; 
  if(CarrierCode === 'HDFC'){
  // Adding specific elements first in the desired order  
    let selectedIndices = [0, 2, 3, 1, 4];
      selectedIndices.forEach(index => {
        getQuoteNewData.Riders[index] &&
        sortedArray.push(getQuoteNewData.Riders[index]);
      });
 
    // if coming any other index case then -  Adding the remaining elements dynamically
      getQuoteNewData.Riders.forEach((rider, index) => {
        if (!selectedIndices.includes(index)) {
          sortedArray.push(rider);
        }
      }); 
  }else{ // in case ICICI 
    sortedArray = getQuoteNewData.Riders;
  }
  //console.log("sortedArray:: ", sortedArray);
 

 

  return (
    <>
      <div
        className={"rightSide_menu_outer ppt_" + getQuoteNewData.PPT}
        style={style}
      >
        <div className="rightSide_menu">
          <div className="rightSide_menu_header">
            <div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block"
                onClick={() => setEditAddOnsFlag(false)}
              >
                {" "}
                Back{" "}
              </button>
            </div>
            <div className="font14 fw500 color182">Update Add Ons Details</div>
            <div>&nbsp;</div>
          </div>

          <form
            onSubmit={handleSubmit(onSubmitData)}
            name="edit_add_ons"
            id="edit_add_ons"
            style={
              {
                /*  'display': 'flex', 'flex-direction': 'column-reverse' */
              }
            }
          >
            <div className="pl20 pr20">
              {getQuoteNewData &&
                getQuoteNewData.Riders &&
                sortedArray.map((Rider, index) => {
                  /*  let min1 = makeInt(Rider.MinSumInsured);
                let  max1 = makeInt(Rider.MaxSuminsured); */

                  const [min1, max1] = min_max_value(Rider);

                  // console.log("min",min1," max", max1);

                  const MatchRider =  riderDetails &&  riderDetails.find(  (master) => master.Rider === Rider.CarrierRiderCode  );
                  //console.log("line 348 --- Rider", Rider);

                  console.log('getQuoteNewData.PolicyLobList[0].RiderDetails', getQuoteNewData.PolicyLobList[0].RiderDetails);
                  // selected AddOns Premium Amount with tax 
                  const row = getQuoteNewData.PolicyLobList[0].RiderDetails.filter( (obj) => obj.Rider === Rider.CarrierRiderCode )
                 // console.log("row===>", row);
                  const addons_premium_amount_with_tax = (row[0] && row[0].Premium) ? formatAmount(( parseInt(row[0].Premium) + parseInt(row[0].TotalTax)) ) : 0;

                  return (
                    <>
                     
                     {
                     CarrierCode === 'HDFC' &&
                     (index === 2 || index === 4) &&  (<h3 className="heading-3" style={{'text-align':'center','margin-top': '5px'}}>OR</h3>) 
                     
                     }

                      <div className="mt20" key={index + 1}>
                        <div className="targetbar_title">
                          <label className="font14 fw500 color182">
                          
                            <input
                              type="checkbox"
                              name={Rider.CarrierRiderCode}
                              className="mr10" 
                              value={Rider.CarrierRiderCode}
                              checked={checkboxes[Rider.CarrierRiderCode]}
                              onChange={handleCheckboxChange}

                            />  

                         {/*    <input
                              type="checkbox"
                              name="accident_addon_chk"                              
                              className="mr10"
                              onChange={(e) => checkAddonChange(e)}
                              value={Rider.CarrierRiderCode}
                              checked={ checkedIciciAddon.includes(  Rider.CarrierRiderCode )  ? true  : false  }
                            /> */}
                            {Rider.CarrierRiderDesc}  {/* Rider.CarrierRiderCode */}
                          </label>
                          <div className="tragetvalue font13 fw600">
                            {" "}
                            ₹
                            {Object.keys(rider_val).length !== 0 &&  `${Rider.CarrierRiderCode}` in rider_val  ? inWords(rider_val[`${Rider.CarrierRiderCode}`])
                              : MatchRider  ? inWords(MatchRider.SumInsured) : inWords(min1)}
                          </div>
                        </div>

                        {
                          /* <div className="text-type-3 ml20">Min 25 k- Max 1.5 Cr</div> */
                          console.log(
                            "Rider.CarrierBasePremium->",
                            Rider.CarrierBasePremium,
                            "MatchRider.CarrierBasePremium",
                            MatchRider
                          )
                        }
                        {/* Rider.BenefitCode */}
                        <Slider
                          name={"rider_options_" + Rider.CarrierRiderCode}
                          className="mt15"
                          step={min1}
                          min={min1}
                          /* max={MatchRider ? MatchRider.SumInsured : (isNaN(max1) ? form_data.SumInsured : max1)} */
                          max={max1}
                          onChange={(e) => {
                            SliderOnChnage(e, Rider.CarrierRiderCode);
                          }}
                          defaultValue={
                            Object.keys(rider_val).length !== 0 &&
                            `${Rider.CarrierRiderCode}` in rider_val
                              ? rider_val[`${Rider.CarrierRiderCode}`]
                              : MatchRider
                              ? MatchRider.SumInsured
                              : min1
                          }
                          pushable={true}
                        />
                        <div className="flex_center justify_center mt8">
                          <div className="valuecount text-type-3">
                            {inWords(min1)}
                          </div>
                          {/*  <div className="valuecount text-type-3">{MatchRider ? inWords(MatchRider.SumInsured) : (isNaN(max1) ? inWords(form_data.SumInsured) : inWords(max1))}</div> */}
                          <div className="valuecount text-type-3">
                            {inWords(max1)}
                          </div>
                        </div>
                      </div>
                      <AddOnsCard1
                        cover_term={ MatchRider ? MatchRider.Term : Rider.MaxTerm  }
                        premium={  addons_premium_amount_with_tax  }
                      />
                      {
                        (Rider.CarrierRiderCode !== 'PPR_ADC' && Rider.CarrierRiderCode  !== 'HPR_CC') && <hr className={"border-hr add-ons-"+Rider.CarrierRiderCode} />
                      }
                      
                    </>
                  );
                })}
            </div>
            <div className="saveBtn backsaveBtn">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setEditAddOnsFlag(false)}
              >
                {" "}
                Cancel{" "}
              </button>
              <button type="submit" className="btn btn-primary">
                {" "}
                Get Quote{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditAddOns;

const AddOnsCard1 = ({ cover_term, premium }) => {
  return (
    <div className="mt10 ">
      <Card className="pt10 pb10 pl20 pr20 card-bg-gray">
        <Row>
          <Col md={6}>
            <span className="text-type-3_1">Cover Term</span>
            <br />
            <span className="font14 fw500 color182">{cover_term} Years</span>
          </Col>
          <Col md={6} className="text-right">
            <span className="text-type-3_1">Premium</span>
            <br />
            <span className="font14 fw500 color182 ">
              ₹ {premium && premium }/Year
            </span>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
